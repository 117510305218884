import mongoose from 'mongoose'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { getCurrentOrgId } from 'src/utils/local_storage'
import { EnvironmentVariables } from '../../models/utils/UtilsInterface'
import { CurrentUserDocument } from '../../models/user/BaseUserInterface'

export function initializeDatadog(publicVariables: EnvironmentVariables): void {
  const env = publicVariables.ENV || 'unknown'
  const service = 'coffeepals-app-frontend'
  const site = 'datadoghq.com'

  datadogLogs.init({
    clientToken: 'pub3383c36f494feae15516ec0cfbdc482b',
    site,
    forwardConsoleLogs: 'all',
    service,
    sessionSampleRate: 100,
    telemetrySampleRate: 0,
    silentMultipleInit: true,
    env,
    beforeSend: (log) => {
      // Ignore some logs that are not actionable
      const ignored = [
        '[Fast Refresh]',
        'You may test your Stripe.js integration over HTTP. However, live Stripe.js integrations must use HTTPS.',
        'Failed to fetch',
        'Fetch error',
        'Skipping auto-scroll behavior due to `position: sticky` or `position: fixed` on element:',
      ]
      // eslint-disable-next-line no-restricted-syntax
      for (const ignore of ignored) {
        if (log.message.includes(ignore)) {
          return false
        }
      }
      return true
    },
  })

  datadogRum.init({
    applicationId: '34a68f15-88f1-4363-9688-5ec75117653e',
    clientToken: 'pub3c02835e554d6eeeebc20bb712a5393a',
    site,
    service,
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    silentMultipleInit: true,
    startSessionReplayRecordingManually: true,
  })
}

export function setDatadogUserContext(user: CurrentUserDocument): void {
  const organizationId = getCurrentOrgId() as string
  const userAndOrgId = {
    id: (user._id as mongoose.Types.ObjectId).toString(),
    organizationId: organizationId,
    enterpriseId: user.enterpriseId,
  }

  datadogLogs.setUser(userAndOrgId)
  datadogRum.setUser(userAndOrgId)
  datadogRum.startSessionReplayRecording()
}

export function clearDatadogUserContext(): void {
  datadogLogs.setUser({})
  datadogRum.setUser({})
  datadogRum.stopSessionReplayRecording()
}
