'use client'

import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { initializeDatadog } from 'integrations/datadog'
import { closeCrisp, initializeCrisp } from 'integrations/crisp'
import { endUserflow, initializeUserflow } from 'integrations/userflow'
import { initializeRudderstack } from 'integrations/rudderstack'
import { useFetchEnvironmentVariables } from 'src/api/utils'
import { useCurrentUser } from 'src/api/user'
import logger from 'src/utils/logger'
import { App_Type_Key } from 'src/utils/axios'

export type APP_TYPE = 'web_app' | 'tab_app' | 'slack_app' | 'teams_app'

type IntegrationsContextType = {
  datadogInitialized: boolean
  crispInitialized: boolean
  userflowInitialized: boolean
  rudderstackInitialized: boolean
  appTypeInitialized: boolean
  appType: APP_TYPE

  setAppType: (appType: APP_TYPE) => void
}

export const IntegrationsContext = createContext({} as IntegrationsContextType)

export const IntegrationsProvider = ({ children }: { children: ReactNode }): React.ReactNode => {
  const setAppType = (appType: APP_TYPE): void => {
    localStorage.setItem(App_Type_Key as string, appType)
    setState((prevState) => ({ ...prevState, appType }))
  }

  const [state, setState] = useState<IntegrationsContextType>({
    datadogInitialized: false,
    crispInitialized: false,
    userflowInitialized: false,
    rudderstackInitialized: false,
    appTypeInitialized: false,
    appType: 'web_app',
    setAppType,
  })

  const { publicVariables } = useFetchEnvironmentVariables()
  const { currentUser, isLoading } = useCurrentUser()

  useEffect(() => {
    const tryInitializeAppTypeSet = (): void => {
      if (state.appTypeInitialized) {
        return
      }
      const appType = localStorage.getItem(App_Type_Key as string) as APP_TYPE

      if (appType) {
        setAppType(appType as APP_TYPE)
      }

      state.appTypeInitialized = true
    }

    const tryInitializeDatadog = (): void => {
      if (state.datadogInitialized || !publicVariables) {
        return
      }
      try {
        initializeDatadog(publicVariables)
        setState((prevState) => ({ ...prevState, datadogInitialized: true }))
      } catch (error) {
        logger.error('Error initializing datadog', { publicVariables }, error as Error)
      }
    }

    const tryInitializeCrisp = (): void => {
      if (state.appType === 'tab_app') {
        closeCrisp()
        return
      }

      if (state.crispInitialized || !publicVariables || !currentUser || state.appType !== 'web_app' || isLoading) {
        return
      }

      try {
        initializeCrisp()
        setState((prevState) => ({ ...prevState, crispInitialized: true }))
      } catch (error) {
        logger.error('Error initializing crisp', error as Error)
      }
    }

    const tryInitializeUserflow = (): void => {
      if (state.appType === 'tab_app') {
        endUserflow()
        return
      }

      if (state.userflowInitialized || state.appType !== 'web_app' || !publicVariables || !currentUser || isLoading) {
        return
      }

      try {
        initializeUserflow(publicVariables)
        setState((prevState) => ({ ...prevState, userflowInitialized: true }))
      } catch (error) {
        logger.error('Error initializing userflow', { publicVariables, currentUser }, error as Error)
      }
    }

    const tryInitializeRudderstack = async (): Promise<void> => {
      if (state.rudderstackInitialized || !publicVariables) {
        return
      }
      try {
        await initializeRudderstack(publicVariables)
        setState((prevState) => ({ ...prevState, rudderstackInitialized: true }))
      } catch (error) {
        logger.error('Error initializing rudderstack', { publicVariables }, error as Error)
      }
    }

    if (currentUser?.userOnboard) {
      tryInitializeCrisp()
      tryInitializeUserflow()
    }
    tryInitializeDatadog()
    tryInitializeRudderstack()
    tryInitializeAppTypeSet()
  }, [publicVariables, currentUser, isLoading, state])

  const memoizedValue = useMemo(() => state, [state])

  return <IntegrationsContext.Provider value={memoizedValue}>{children}</IntegrationsContext.Provider>
}
